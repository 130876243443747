import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/app-check';
import { CreateJobForm } from '../pages/CreateJob';
import { ContactUSForm } from '../pages/ContactUS';

const firebaseConfig = {
  apiKey: "AIzaSyCuCgk27WZT6EcDAoKaEuV97O_EpNfWvN4",
  authDomain: "vrekrut-prod.firebaseapp.com",
  projectId: "vrekrut-prod",
  storageBucket: "vrekrut-prod.appspot.com",
  messagingSenderId: "883695898561",
  appId: "1:883695898561:web:2040e6e3f0e542ac49a940",
  measurementId: "G-WW3VPX5CWL",
  databaseURL: 'DATABASE_URL'
};

firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
// eslint-disable-next-line no-restricted-globals

appCheck.activate('6LdYfZMbAAAAAPVm-iKJlWX8WKCakFDOYoiLsYB4');

firebase.analytics();

export const JOBS_COLLECTION = firebase
  .firestore()
  .collection('jobs') as firebase.firestore.CollectionReference<
  CreateJobForm & { endDate: firebase.firestore.Timestamp }
>;

export const USERS = firebase
  .firestore()
  .collection('users') as firebase.firestore.CollectionReference<{
  group: string[];
}>;

export const CONTACT_US_SUBMISSION_COLLECTION = firebase
  .firestore()
  .collection(
    'contact-us-submission'
  ) as firebase.firestore.CollectionReference<ContactUSForm>;

export const JOBS_APPLICATION_COLLECTION = firebase
  .firestore()
  .collection(
    'jobs-submission'
  ) as firebase.firestore.CollectionReference<JobsSubmission>;

export const JOBS_PROFILE_COLLECTION = firebase
  .firestore()
  .collection(
    'profile'
  ) as firebase.firestore.CollectionReference<JobsSubmission>;

export const GET_COMMENT_FOR_USER = (submissionId: string) => {
  return firebase
    .firestore()
    .collection('jobs-submission')
    .doc(submissionId)
    .collection('notes') as firebase.firestore.CollectionReference<Notes>;
};

export interface Notes {
  id?: string;
  name: string;
  date?: string;
  insertDate: Date;
  body: string;
}

/**
 * @deprecated
 */
export interface Job {
  id: string;
  title: string;
  location: string;
  industry: string;
  startTime: firebase.firestore.Timestamp;
  requirement: string[];
  endTime: firebase.firestore.Timestamp;
  department: string[];
  country: string;
  description: string;
  salary: string;
  type: string;
}

export interface JobsSubmission {
  note?: string;
  id?: string;
  uid: string;
  jobId: string;
  firstName: string;
  appliedJob?: string[];
  lastName: string;
  mobile: string;
  email: string;
  dob: string;
  delivery?: {
    startTime: firebase.firestore.Timestamp;
  };
  martialStatus: string;
  gender: string;
  nationality: string;
  currentLocation: string;
  city: string;
  state: string;
  experience: string;
  educationQualification: string;
  noticePeriod: string;
  visaStatus: string;
  GCCDrivingLicense: string;
  currentSalary: string;
  expectedSalary: string;
  linkedInProfile: string;
  coverLetter: string;
  howDidYouHearAboutUs: string;
  resume: string;
  agreeToTOS: boolean;
  age?: number;
}
