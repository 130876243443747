import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import './db/index';

const DashboardContactUSForm = React.lazy(
  () => import('./pages/DashboardContactUSForm')
);
const DashboardSearch = React.lazy(() => import('./pages/DashboardSearch'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const ProfilePage = React.lazy(() => import('./pages/Profile'));
const ServicePage = React.lazy(() => import('./pages/Service'));
const ForgetPassword = React.lazy(() => import('./pages/ForgetPassword'));
const Apply = React.lazy(() => import('./pages/Apply'));
const HomePage = React.lazy(() => import('./pages/Home'));
const JobInfo = React.lazy(() => import('./pages/JobInfo'));
const Thanks = React.lazy(() => import('./pages/Thanks'));
const ContactUS = React.lazy(() => import('./pages/ContactUS'));
const AboutUS = React.lazy(() => import('./pages/AboutUS'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const DashboardJobInfo = React.lazy(
  () => import('./pages/DashboardCandidateInfo')
);
const CreateJob = React.lazy(() => import('./pages/CreateJob'));
const JobsList = React.lazy(() => import('./pages/Jobs'));
const DashboardJobCandidates = React.lazy(
  () => import('./pages/DashboardJobCadidates')
);
const Dashboard = React.lazy(() => import('./pages/Dashboard'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/" exact={true}>
            <HomePage />
          </Route>
          <Route path="/job/:id/apply">
            <Apply />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>
          <Route path="/job/:id/submitting/:subId">
            <Thanks />
          </Route>
          <Route path="/job/:id">
            <JobInfo />
          </Route>
          <Route path="/about-us">
            <AboutUS />
          </Route>
          <Route path="/forget-password">
            <ForgetPassword />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/contact-us">
            <ContactUS />
          </Route>
          <Route path="/jobs">
            <JobsList />
          </Route>
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/services">
            <ServicePage />
          </Route>
          <Route path="/dashboard/create-job/:id">
            <CreateJob />
          </Route>
          <Route path="/dashboard/create-job">
            <CreateJob />
          </Route>
          <Route path="/dashboard/contact-us">
            <DashboardContactUSForm />
          </Route>
          <Route path="/dashboard/search">
            <DashboardSearch />
          </Route>
          <Route path="/dashboard/job/:id/:candidate">
            <DashboardJobInfo />
          </Route>
          <Route path="/dashboard/job/:id">
            <DashboardJobCandidates />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
