import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userInfo, setUserInfo] = useState<firebase.User | undefined>();

  useEffect(() => {
    const sub = firebase.auth().onAuthStateChanged((a) => {
      setUserInfo(a);
    });

    return () => {
      sub();
    };
  }, []);

  return (
    <AuthContext.Provider value={userInfo}>{children}</AuthContext.Provider>
  );
}

export const AuthContext = React.createContext<firebase.User | undefined>(
  undefined
);
